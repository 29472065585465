import {HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {inject} from "@angular/core";
import {Router} from '@angular/router';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const userKey = localStorage.getItem('userKey');
  const token = localStorage.getItem('token');
  const ssid = localStorage.getItem('ssid');

  const router = inject(Router); // Injecting the Router

  if (token) {
    req = req.clone({
      setHeaders: {
        'userKey': userKey || '',
        'Authorization': `Bearer ${token}`,
        'ssid': ssid || ''
      }
    });
  }

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401 || error.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('userKey');
        localStorage.removeItem('ssid');
        router.navigate(['/auth/login']);
      }
      return throwError(error);
    })
  );
};

import {Component} from '@angular/core';
import {Router, RouterModule, RouterOutlet} from "@angular/router";
import {ListboxModule} from "primeng/listbox";
import {MenuItem, PrimeTemplate} from "primeng/api";
import {SplitterModule} from "primeng/splitter";
import {FormsModule} from "@angular/forms";
import {ToolbarModule} from "primeng/toolbar";
import {PanelMenuModule} from "primeng/panelmenu";
import {ButtonDirective} from "primeng/button";
import {Store} from "@ngrx/store";
import {AppState} from "../../../store/AppState";
import * as AuthActions from '../../../features/auth/store/actions/auth.action'
import {MenubarModule} from "primeng/menubar";
import {AvatarModule} from "primeng/avatar";
import {MenuModule} from "primeng/menu";
import {selectUserInformation} from "../../../features/auth/store/selectors/auth.selector";
import {emptyUser, UserDto} from "../../../features/auth/store/models/User.model";
import * as Permissions from '../../../features/admin/Position/permissions'
import {environment} from '../../../../environments/environment'
import {ToastModule} from "primeng/toast";


interface PagesRouter {
  name: string,
  slug: string
  path: string
}

@Component({
  selector: 'app-side-bar',
  standalone: true,
  imports: [
    RouterOutlet, RouterModule,
    ListboxModule,
    PrimeTemplate,
    SplitterModule,
    FormsModule,
    ToolbarModule,
    PanelMenuModule,
    ButtonDirective,
    MenubarModule, AvatarModule, MenuModule, ToastModule
  ],
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.css'
})
export class SideBarComponent {
  showSidebar: boolean = true;
  userData: UserDto = emptyUser;
  items = [
    {
      label: 'Home',
      icon: 'pi pi-fw pi-home',
      permission: Permissions.MODULE_HOME,
      routerLink: ['/'],
    },
    {
      label: 'Sales',
      icon: 'pi pi-fw pi-users',
      permission: Permissions.MODULE_SALES,
      items: [
        {
          label: 'Contract',
          icon: 'pi pi-fw pi-users',
          permission: Permissions.FEATURE_SALES_CONTRACT,

          items: [
            {
              label: 'Contract',
              icon: 'pi pi-fw pi-indian-rupee',
              permission: Permissions.PAGE_SALES_CONTRACT_CONTRACT,
              routerLink: ['/sales/contract/contract'],
            },
            {
              label: 'Sub Contract',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_SALES_CONTRACT_SUB_CONTRACT,
              routerLink: ['/sales/contract/subContract'],
            },
            {
              label: 'Sub Contract Report',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_SALES_CONTRACT_SUB_CONTRACT_REPORT,
              routerLink: ['/sales/contract/subContract/report'],
            },
          ]
        },
        {
          label: 'Billing',
          icon: 'pi pi-fw pi-money-bill',
          permission: Permissions.FEATURE_SALES_BILLING,

          items: [
            {
              label: 'Client',
              icon: 'pi pi-fw pi-users',
              permission: Permissions.PAGE_SALES_BILLING_CLIENT,
              routerLink: ['/sales/billing/client'],
            },
            {
              label: 'Package',
              icon: 'pi pi-fw pi-users',
              permission: Permissions.PAGE_SALES_BILLING_PACKAGE,
              routerLink: ['/sales/billing/package'],
            },
            {
              label: 'Package Group',
              icon: 'pi pi-fw pi-users',
              permission: Permissions.PAGE_SALES_BILLING_PACKAGE_GROUP,
              routerLink: ['/sales/billing/packageGroup'],
            },
          ]
        }
      ]
    },
    {
      label: 'PMS',
      icon: 'pi pi-fw pi-briefcase',
      permission: Permissions.MODULE_PMS,

      items: [
        {
          label: 'Timesheet',
          icon: 'pi pi-fw pi-users',
          permission: Permissions.FEATURE_PMS_TIMESHEET,
          items: [
            {
              label: 'Timesheet',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_PMS_TIMESHEET_TIMESHEET,
              routerLink: ['/pms/timesheet/timesheet'],
            },
            {
              label: 'Timesheet Report',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_PMS_TIMESHEET_REPORT,
              routerLink: ['/pms/timesheet/timesheetReport'],
            },
            {
              label: 'Approvals',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_PMS_TIMESHEET_APPROVALS,
              routerLink: ['/pms/timesheet/timesheetLock'],
            },
          ]
        },
        {
          label: 'Tasks',
          icon: 'pi pi-fw pi-users',
          permission: Permissions.FEATURE_PMS_TASK,

          items: [
            {
              label: 'Production Tasks',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_PMS_TASK_PRODUCTION_TASK,
              routerLink: ['/pms/tasks/production'],
            },
            {
              label: 'HR Tasks',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_PMS_TASK_HR_TASK,
              routerLink: ['/pms/tasks/hr'],
            },
            {
              label: 'Account Tasks',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_PMS_TASK_ACCOUNT_TASK,
              routerLink: ['/pms/tasks/account'],
            },
            {
              label: 'BDE Tasks',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_PMS_TASK_BDE_TASK,
              routerLink: ['/pms/tasks/bde'],
            },
            {
              label: 'Marketing Tasks',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_PMS_TASK_MARKETING_TASK,
              routerLink: ['/pms/tasks/marketing'],
            },
          ]
        },
        {
          label: 'Capacity',
          icon: 'pi pi-fw pi-users',
          permission: Permissions.FEATURE_PMS_CAPACITY,

          items: [
            {
              label: 'Resource',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_PMS_CAPACITY_RESOURCE_OVERVIEW,
              routerLink: ['/pms/capacity/overview'],
            },
          ]
        }
      ]
    },
    {
      label: 'Admin',
      icon: 'pi pi-fw pi-desktop ',
      firstTime: true,
       permission: Permissions.MODULE_ADMIN,
      items: [
        {
          label: 'Permission',
          icon: 'pi pi-fw pi-users',
          firstTime: true,
          permission: Permissions.FEATURE_ADMIN_PERMISSION,
          items: [
            {
              label: 'Position', icon: 'pi pi-fw pi-objects-column',
              firstTime: true,
              permission: Permissions.PAGE_ADMIN_PERMISSION_POSITION,
              routerLink: ['/admin/positions/positions'],
            },
          ]
        },
      ]
    },
    {
      label: 'HR',
      icon: 'pi pi-fw pi-building',
      permission: Permissions.MODULE_HR,

      items: [
        {
          label: 'Performance',
          icon: 'pi pi-fw pi-users',
          permission: Permissions.FEATURE_HR_PERFORMANCE,
          items: [
            {
              label: 'KRA Timeframe',
              permission: Permissions.PAGE_HR_PERFORMANCE_KRA_TIMEFRAME,
              icon: 'pi pi-fw pi-objects-column', routerLink: ['/hr/performance/kra-timeframe'],
            },
            {
              label: 'KRA', icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_HR_PERFORMANCE_MY_KRA,
              routerLink: ['/hr/performance/kra/my-kra'],
            },

          ]
        },
        {
          label: 'Leave',
          icon: 'pi pi-fw pi-users',
          permission: Permissions.FEATURE_HR_LEAVE,

          items: [
            {
              label: 'Leave',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_HR_LEAVE_MY_LEAVE,
              routerLink: ['/hr/leave/myLeave'],
            },
            {
              label: 'myLeave Report',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_HR_LEAVE_REPORT,
              routerLink: ['/hr/performance/kra'],
            },
          ]
        },
        {
          label: 'Organization',
          icon: 'pi pi-fw pi-users',
          permission: Permissions.FEATURE_HR_ORG,
          items: [
            {
              label: 'Employee',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_HR_ORG_EMPLOYEE,
              routerLink: ['/hr/org/employee'],
            },
            {
              label: 'Holiday',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_HR_ORG_HOLIDAY,
              routerLink: ['/hr/org/holiday'],
            },
            {
              label: 'Team',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_HR_ORG_TEAM,
              routerLink: ['/hr/org/team'],
            },
            {
              label: 'kra',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_HR_ORG_KRA,
              routerLink: ['/hr/org/kra'],
            },
            {
              label: 'kraTemplate',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_HR_ORG_KRA_TEMPLATE,
              routerLink: ['/hr/org/kraTemplate'],
            },
            {
              label: 'Attendance',
              icon: 'pi pi-fw pi-objects-column',
              permission: Permissions.PAGE_HR_ORG_ATTENDANCE,
              routerLink: ['/hr/org/attendance'],
            },
          ]
        },
      ]
    },
    // Add more items as needed
  ];
  permissionsItems: MenuItem[] = [];
  UserItems: MenuItem[] | undefined;

  constructor(private router: Router, private store: Store<AppState>,) {
    this.store.select(selectUserInformation).subscribe(dataState => {
      this.userData = dataState
      if (this.UserItems && this.userData && this.userData.id) {
        this.showSidebar = true;
        this.UserItems[0].label = this.userData.name;
      } else {
        this.showSidebar = environment.firstTime;
      }
      this.permissionsItems = this.items.reduce((acc: any[], v: any) => {
        let k = this.itemCheck(v)
        if (k) {
          acc.push(k)
        }
        return acc
      }, [])

    })
    const that = this
    this.UserItems = [
      {
        label: "abc",
        items: [
          {
            label: 'Update',
            icon: 'pi pi-refresh',
            routerLink: ['/auth/userDetails'],
          },
          {
            label: 'Subscription',
            icon: 'pi pi-bell',
            routerLink: ['/auth/subscription'],
          },
          {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => {
              that.store.dispatch(AuthActions.logout());
            },
          }
        ]
      }
    ]
  }


  ngOnInit() {
  }

  itemCheck(item: any): any {
    let i: any = {}
    if (item.permission) {
      if (item.firstTime && environment.firstTime) {
        i.label = item.label;
        i.icon = item.icon;
        if (item.routerLink) {
          i.routerLink = item.routerLink;
        }
      } else {
        let k = this.userData.position.permissions.filter(v => v.permission == item.permission)
        if (k[0]) {
          i.label = item.label;
          i.icon = item.icon;
          if (item.routerLink) {
            i.routerLink = item.routerLink;
          }
        } else {
          return undefined;
        }
      }
    } else {
      i.label = item.label;
      i.icon = item.icon;
      if (item.routerLink) {
        i.routerLink = item.routerLink;
      }
    }

    if (item.items) {
      i.items = item.items.reduce((acc: any[], v: any) => {
        let k = this.itemCheck(v)
        if (k) {
          acc.push(k)
        }
        return acc
      }, [])
    }

    return i;
  }

}

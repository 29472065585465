import {ApplicationConfig, importProvidersFrom, provideZoneChangeDetection} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "../environments/environment";
import {authInterceptor} from "./core/interceptors/auth.interceptor";
import {SalesRoutingModule} from "./features/sales/sales-routing.module";
import {AuthRoutingModule} from "./features/auth/auth-routing.module";
import {DatePipe} from "@angular/common";
import {MessageService} from "primeng/api";

export const appConfig: ApplicationConfig = {
   providers: [
     provideZoneChangeDetection({ eventCoalescing: true }),
     provideRouter(routes),
     DatePipe,
     provideHttpClient(withInterceptors([authInterceptor])) ,
     provideAnimationsAsync(),
     MessageService,
     importProvidersFrom(
       StoreModule.forRoot(),
       EffectsModule.forRoot(),
       SalesRoutingModule,
       AuthRoutingModule,
       StoreDevtoolsModule.instrument({
         maxAge: 25,
         logOnly: environment.production,
       })
     ),
   ]
};

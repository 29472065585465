import {Component} from '@angular/core';
import {SideBarComponent} from "./shared/module/side-bar/side-bar.component";
import {Store} from "@ngrx/store";
import {AppState} from "./store/AppState";
import * as AuthActions from './features/auth/store/actions/auth.action';
import {Router, RouterModule, RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    SideBarComponent,
    RouterOutlet, RouterModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  constructor(private store: Store<AppState>, private router: Router) {
  }

  ngOnInit() {
    this.store.dispatch(AuthActions.loadAuthFromLocalStorage());
  }
}

<p-toolbar>
  <div class="p-toolbar-group-left">
    <span class="p-mr-2">Code perts Portal</span>
  </div>
  <div class="p-toolbar-group-right">

    <p-menu #menu [model]="UserItems" [popup]="true" />
    <p-avatar
      image="https://primefaces.org/cdn/primeng/images/demo/avatar/onyamalimba.png"
      styleClass="mr-2"
      (click)="menu.toggle($event)"
      shape="circle" />
  </div>
</p-toolbar>

<div class="layout-wrapper">
  @if (showSidebar) {
    <div class="sidebar">
      <p-panelMenu [model]="permissionsItems"></p-panelMenu>
    </div>
  }
  <div class="main-container2">
    <router-outlet></router-outlet>
  </div>
</div>

<p-toast position="bottom-left" />

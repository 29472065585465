import {Routes} from '@angular/router';
import {authGuard} from "./core/guards/auth.guard";
import * as Permissions from './features/admin/Position/permissions'
export const routes: Routes = [
  {
    path: 'sales',
    loadChildren: () => import('./features/sales/sales-routing.module').then(m => m.SalesRoutingModule),
    canActivate: [authGuard],
    data: {permission: Permissions.MODULE_SALES}
  },
  {
    path: 'pms',
    loadChildren: () => import('./features/pms/pms-routing.module').then(m => m.PMSRoutingModule),
    canActivate: [authGuard],
    data: {permission: Permissions.MODULE_PMS}
  },
  {
    path: 'hr',
    loadChildren: () => import('./features/hr/hr-routing.module').then(m => m.HRRoutingModule),
    canActivate: [authGuard],
    data: {permission: Permissions.MODULE_HR}
  },
  {
    path: 'admin',
    loadChildren: () => import('./features/admin/admin-routing.module').then(m => m.HRRoutingModule),
  },
  {
    path: '',
    loadChildren: () => import('./features/auth/auth-routing.module').then(m => m.AuthRoutingModule),
  },

];
